require.context('../images', true);

// require('../src/stylesheets/cookies_banner');

// for cookies_eu
import Cookies from 'js-cookie';
window.Cookies = Cookies;

import "./gems.js.erb";


$ = require('jquery');
$(document).ready(function() {
    // $('.categorizable').categorizable();
    // $('.limitable').limitable();
    // $('.replaceable').replaceable();
    $('.kwUtilsAccordion').each(function() {
        $(this).find('[data-accordion-toggle]').on('click', function() {
            let expanded = $(this).closest('.kwUtilsAccordion').find('[aria-expanded]');
            expanded.attr('aria-expanded', expanded.attr('aria-expanded') == 'true' ? 'false' : 'true');
        });
    });
});
